<!-- 系统设置 系统消息 消息管理 -->
<template>
  <scroll-layout>
    <template slot="header">
      <ykc-search
        ref="YkcSearch"
        :data="searchData"
        :searchForm="searchParams"
        @click="handleSearchConfirm"
        @clean="handleSearchClean"></ykc-search>
    </template>
    <div class="motorcade-main-account">
      <ykc-table
        ref="YkcTable"
        operateFixedType="right"
        :data="tableData"
        :title="tableTitle"
        :columns="tableColumns"
        :operateButtons="tableOperateButtons.filter(item => item.enabled())"
        :selectable="true"
        :selectOnIndeterminate="false"
        @select="handleSelect"
        @selection-change="selectionChange"
        :rowSelectable="
          row => {
            if (['0', '2'].includes(row.downloadStatus)) {
              return false;
            } else {
              return true;
            }
          }
        ">
        <template #headerButtons>
          <div class="table-header-button">
            <ykc-button v-rbac="'sys:download:batchdownload'" type="plain" @click="batchDownload">
              批量下载
            </ykc-button>
          </div>
        </template>
        <ykc-pagination
          slot="pagination"
          ref="YkcPagination"
          :total="pageInfo.total"
          :current-page.sync="pageInfo.pageIndex"
          :page-size.sync="pageInfo.pageSize"
          @current-change="handlePaginationCurrentChange"
          @size-change="handlePaginationSizeChange"></ykc-pagination>
      </ykc-table>
    </div>
  </scroll-layout>
</template>

<script>
  import YkcDialog from '@/components/ykc-ui/dialog';
  import { sysDownload } from '@/service/apis';
  import { localGetItem, sessionGetItem, code as hasPermission } from '@/utils';

  export default {
    name: 'noticeManageList',
    computed: {
      searchData() {
        return [
          {
            comName: 'YkcInput',
            key: 'fileName',
            label: '文件名称',
            placeholder: '请输入文件名称',
          },
          {
            comName: 'YkcDropdown',
            key: 'downloadType',
            label: '下载类型',
            placeholder: '请选择下载类型',
            remote: true,
            configSet: {
              label: 'name',
              value: 'code',
            },
            data: localGetItem('dictionary')?.download_type || [],
          },
          {
            comName: 'YkcDropdown',
            key: 'downloadStatus',
            label: '生成状态',
            placeholder: '请选择生成状态',
            data: [
              {
                id: '0',
                name: '生成中',
              },
              {
                id: '1',
                name: '生成成功',
              },
              {
                id: '2',
                name: '生成失败',
              },
            ],
          },
        ];
      },
    },
    data() {
      return {
        ruleForm: {},
        showCwd: false,
        selectIds: [],
        tableData: [],

        tableColumns: [
          { label: '文件名称', prop: 'fileName', minWidth: '140px' },
          { label: '生成时间', prop: 'fileGenerateTime', minWidth: '140px' },
          {
            label: '下载类型',
            prop: 'downloadType',
            minWidth: '80px',
            scopedSlots: {
              default: ({ row }) => {
                const downloadType = this.getDownloadDic(row.downloadType);
                return (
                  <div>
                    <span title={downloadType}>{downloadType}</span>
                  </div>
                );
              },
            },
          },
          {
            label: '状态',
            prop: 'downloadStatus',
            minWidth: '100px',
            scopedSlots: {
              default: ({ row }) => {
                const downloadStatusMap = {
                  0: '生成中',
                  1: '生成成功',
                  2: '生成失败',
                  null: '——',
                };
                const downloadStatus = downloadStatusMap[row.downloadStatus] || '——';
                return (
                  <div>
                    <span title={downloadStatus}>{downloadStatus}</span>
                  </div>
                );
              },
            },
          },
        ],
        tableTitle: '下载中心',
        tableOperateButtons: [
          {
            enabled: () => hasPermission('sys:download:del'),
            id: '1',
            text: '删除',
            handleClick: (btn, { row }) => {
              this.deleteDialog(row);
            },
          },
          {
            enabled: () => hasPermission('sys:download:batchdownload'),
            render: (h, button, { row }) => {
              return (
                <ykc-button
                  type="text"
                  disabled={['0', '2'].includes(row.downloadStatus)}
                  class="do-enable-btn"
                  onClick={() => {
                    this.doDownload(row);
                  }}>
                  下载
                </ykc-button>
              );
            },
          },
        ],
        pageInfo: {
          total: 0,
          pageIndex: 1,
          pageSize: 10,
        },
        searchParams: {
          fileName: '',
          downloadStatus: '',
          downloadType: '',
        },
      };
    },
    mounted() {
      this.searchTableList();
    },
    methods: {
      getDownloadDic(code) {
        const download_type = localGetItem('dictionary')?.download_type || [];
        const [property] = download_type.filter(item => item.code === code);
        return property ? property.name : '——' || '——';
      },
      // 下载
      doDownload(item) {
        sysDownload.download(item.id).then(res => {
          window.open(res);
        });
      },
      /** 根据条件查询数据 */
      searchTableList() {
        console.log(this.searchParams);
        const reqParams = {
          ...this.searchParams,
          current: this.pageInfo.pageIndex,
          size: this.pageInfo.pageSize,
          createdBy: sessionGetItem('accountId'),
        };
        sysDownload
          .queryDownFileList(reqParams)
          .then(res => {
            console.log(res);
            this.tableData = res?.records || [];
            this.pageInfo.total = res?.total;
          })
          .catch(() => {
            this.tableData = [];
            this.pageInfo.total = 0;
          });
      },
      /** 点击查询按钮 */
      handleSearchConfirm(form) {
        console.log('form', form);
        this.pageInfo.pageIndex = 1;
        Object.assign(this.searchParams, form);
        this.searchTableList();
      },
      /** 点击清空查询按钮 */
      handleSearchClean(form) {
        Object.assign(this.searchParams, form);
      },
      /** 分页 change 事件监听器 */
      handlePaginationCurrentChange(current) {
        this.pageInfo.pageIndex = current;
        this.searchTableList();
      },
      /** 分页 size  change  事件监听器 */
      handlePaginationSizeChange(size) {
        this.pageInfo.pageIndex = 1;
        this.pageInfo.pageSize = size;
        this.searchTableList();
      },
      // 勾选
      handleSelect(selection, row) {
        console.log(selection, row);
      },
      /**
       * 获取勾选id
       * */
      selectionChange(selection, row) {
        console.log(selection, row);
        this.selectIds = selection.map(item => {
          return item.id;
        });
        console.log(this.selectIds);
      },
      // 批量下载
      batchDownload() {
        if (this.selectIds.length === 0) {
          this.$message.warning('请选择条目后进行批量操作！');
          return;
        }
        sysDownload.batchDownload(this.selectIds).then(res => {
          if (res && res.length > 0) {
            for (let i = 0; i < res.length; i++) {
              const hiddenIFrameID = `hiddenDownloader${i}`;
              const iframe = document.createElement('iframe');
              iframe.id = hiddenIFrameID;
              iframe.style.display = 'none';
              document.body.appendChild(iframe);
              iframe.src = res[i]; // 直接下载，不会弹出新的页面
            }
          }
        });
      },
      // 删除
      deleteDialog(row) {
        console.log('row', row);
        const self = this;
        YkcDialog({
          showTitle: false,
          showSuccessIcon: false,
          type: 'success',
          dialogType: 'feedback',
          desc: '删除文件后无法恢复，请谨慎操作！',
          onClose(done) {
            done();
          },
          onCancel(done) {
            done();
          },
          onConfirm(done) {
            sysDownload.delete(row.id).then(() => {
              self.searchTableList();
            });
            done();
          },
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .ykc-table-cell-wrap-enable {
    position: relative;
    padding-left: 9px;
    &::before {
      content: ' ';
      position: absolute;
      top: calc(50% - 2.5px);
      left: 0;
      width: 5px;
      height: 5px;
      background: #02c498;
      border-radius: 50%;
    }
  }
  .ykc-table-cell-wrap-deactivate {
    position: relative;
    padding-left: 9px;
    &::before {
      content: ' ';
      position: absolute;
      top: calc(50% - 2.5px);
      left: 0;
      width: 5px;
      height: 5px;
      background: #d0021b;
      border-radius: 50%;
    }
  }
</style>
