<!-- 系统设置 系统消息 消息管理 新增 -->
<template>
  <ykc-drawer :show.sync="showDrawer" destroy-on-close :title="drawerTitle">
    <template #footer>
      <div class="drawer-button-wrap">
        <template v-for="(btn, i) in drawerButtons">
          <ykc-button :type="btn.type" v-if="btn.enabled()" :key="i" @click="btn.handler">
            {{ btn.label }}
          </ykc-button>
        </template>
      </div>
    </template>
    <div class="drawer-main-body">
      <ykc-form>
        <el-steps :active="stepsIndex" align-center finish-status="success">
          <el-step title="公告消息" />
          <el-step title="发布配置" />
        </el-steps>
        <div style="padding-top: 20px">
          <ykc-form ref="YkcForm0" :model="model" :rules="rules" v-show="stepsIndex === 0">
            <ykc-form-item label="公告标题" prop="noticeTitle">
              <ykc-input
                v-model="model.noticeTitle"
                maxlength="50"
                placeholder="请输入公告标题"></ykc-input>
            </ykc-form-item>
            <ykc-form-item prop="noticeType" label="公告类型">
              <ykc-radio :data="noticeTypeList" v-model="model.noticeType"></ykc-radio>
            </ykc-form-item>
            <ykc-form-item label="公告内容" prop="noticeContent">
              <ykc-input
                type="textarea"
                v-model="model.noticeContent"
                maxlength="200"
                placeholder="请输入公告内容"></ykc-input>
            </ykc-form-item>
          </ykc-form>
          <ykc-form ref="YkcForm1" :model="model" :rules="rules" v-show="stepsIndex === 1">
            <ykc-form-item label="公告发布时间" prop="pushTime">
              <ykc-date-picker
                popper-class="notice-manage-add-picker"
                v-model="model.pushTime"
                type="datetime"
                limitDate="limitBefore"
                format="yyyy-MM-dd HH:mm"></ykc-date-picker>
            </ykc-form-item>
            <ykc-form-item label="公告发布商户" prop="operatorIdList">
              <ykc-tree
                nodeKey="operatorId"
                :data="treeData"
                :props="treeProp"
                ref="YkcTree"
                @check-change="handleTreeCheckChange"></ykc-tree>
            </ykc-form-item>
          </ykc-form>
        </div>
      </ykc-form>
    </div>
  </ykc-drawer>
</template>

<script>
  import dayjs from 'dayjs';
  import { sysNotice, operatorData } from '@/service/apis';
  import regexpObj from '@/utils/regexp';
  import YkcDialog from '@/components/ykc-ui/dialog';

  const initModel = () => ({
    noticeTitle: '',
    noticeType: 0,
    noticeContent: '',
    pushTime: '',
    operatorIdList: [],
  });

  export default {
    props: ['showDrawer'],
    watch: {
      showDrawer(val) {
        if (val) {
          this.handleOpen();
        }
      },
    },
    data() {
      return {
        maxIndex: 1,
        stepsIndex: 0,
        drawerButtons: [
          {
            label: '取消',
            type: 'plain',
            enabled: () => {
              return true;
            },
            handler: () => {
              YkcDialog({
                showTitle: false,
                showFooter: true,
                dialogType: 'feedback',
                desc: '取消后操作将不被保存，确认是否取消？',
                onCancel: dialogDone => {
                  dialogDone(); // 隐藏弹窗
                  this.model = initModel();
                },
                onConfirm: dialogDone => {
                  dialogDone(); // 隐藏弹窗
                  this.stepsIndex = 0;
                  this.model = initModel();
                  this.$emit('closeDrawer');
                },
              });
            },
          },
          {
            label: '上一步',
            enabled: () => {
              return this.stepsIndex !== 0;
            },
            handler: () => {
              this.$refs.YkcForm1.resetFields();
              this.stepsIndex--;
            },
          },
          {
            label: '下一步',
            enabled: () => {
              return this.stepsIndex !== this.maxIndex;
            },
            handler: () => {
              this.$refs.YkcForm0.validate(valid => {
                if (valid) {
                  this.stepsIndex++;
                  this.getMenuTree();
                }
              });
            },
          },
          {
            label: '保存',
            enabled: () => {
              return this.stepsIndex === this.maxIndex;
            },
            handler: () => {
              this.$refs.YkcForm1.validate(valid => {
                if (valid) {
                  console.log('YkcForm1');
                  const reqParams = {
                    ...this.model,
                  };
                  console.log(reqParams);
                  sysNotice.save(reqParams).then(res => {
                    console.log(res);
                    this.stepsIndex = 0;
                    this.model = initModel();
                    this.$emit('closeDrawer');
                    this.$emit('finish');
                  });
                }
              });
            },
          },
        ],
        drawerTitle: '公告新增',
        noticeTypeList: [
          {
            id: 0,
            name: '营销公告',
          },
          {
            id: 1,
            name: '系统公告',
          },
        ],
        model: initModel(),
        rules: {
          noticeTitle: [
            { required: true, message: '请输入公告标题', trigger: 'blur' },
            {
              pattern: regexpObj.regexp.input.nameType.regexp,
              trigger: 'blur',
              message: regexpObj.regexp.input.nameType.errorTips.error('公告标题'),
            },
          ],
          noticeType: [{ required: true }],
          noticeContent: [
            { required: true, message: '请输入公告内容', trigger: 'blur' },
            {
              pattern: regexpObj.regexp.input.textAreaType.regexp,
              trigger: 'blur',
              message: regexpObj.regexp.input.textAreaType.errorTips.error('公告内容'),
            },
          ],
          pushTime: [
            { required: true, message: '请选择公告发布时间', trigger: 'blur' },
            {
              validator: this.checkTime,
              trigger: 'blur',
            },
          ],
          operatorIdList: [
            { required: true, type: 'array', message: '请选择关联公告发布商户', trigger: 'blur' },
          ],
        },
        fleetData: [],
        treeData: [],
        treeProp: {
          label: 'name',
          children: 'subNodes',
          disabled: 'disabled',
        },
      };
    },
    methods: {
      checkTime(rule, value, cb) {
        if (dayjs().isBefore(value, 'minute')) {
          cb();
        } else {
          cb('发布时间不能小于当前时间');
        }
      },
      // 抽屉展示
      handleOpen() {
        this.model = initModel();
        this.getMenuTree();
      },
      // 菜单树变化
      handleTreeCheckChange() {
        // this.model.operatorIdList = [...nodeIds];
        const keys = this.$refs.YkcTree.getCheckedKeys(true);
        console.log('keys', keys);
        this.model.operatorIdList = keys;
      },
      // 菜单树初始化
      async getMenuTree() {
        this.treeData = [];
        this.model.operatorIdList = [];
        operatorData.userOperatorList({ name: '' }).then(res => {
          console.log(res);
          this.treeData = res || [];
        });
      },
    },
  };
</script>

<style>
  .notice-manage-add-picker .el-picker-panel__footer .el-button--text {
    display: none !important;
  }
</style>
