<!-- 系统设置 系统消息 消息管理 -->
<template>
  <scroll-layout>
    <template slot="header">
      <ykc-search
        ref="YkcSearch"
        :data="searchData"
        :searchForm="searchParams"
        @click="handleSearchConfirm"
        @clean="handleSearchClean"></ykc-search>
    </template>
    <div class="motorcade-main-account">
      <ykc-table
        ref="YkcTable"
        operateFixedType="right"
        :data="tableData"
        :title="tableTitle"
        :columns="tableColumns"
        :operateButtons="tableOperateButtons.filter(item => item.enabled())"
        :selectable="true"
        :selectOnIndeterminate="false"
        @select="handleSelect"
        @selection-change="selectionChange">
        <template #headerButtons>
          <div class="table-header-button">
            <ykc-button v-rbac="'sys:notice:add'" type="plain" @click="add">新增</ykc-button>
            <ykc-button v-rbac="'sys:notice:del'" type="plain" @click="batchDelete">
              批量删除
            </ykc-button>
            <ykc-button v-rbac="'sys:notice:batchdownload'" type="plain" @click="batchDownload">
              批量下载
            </ykc-button>
          </div>
        </template>
        <ykc-pagination
          slot="pagination"
          ref="YkcPagination"
          :total="pageInfo.total"
          :page-size.sync="pageInfo.pageSize"
          :current-page.sync="pageInfo.pageIndex"
          @size-change="handlePaginationSizeChange"
          @current-change="handlePaginationCurrentChange"></ykc-pagination>
      </ykc-table>
    </div>
    <AddOrEdit
      :showDrawer="addEditDrawerVisible"
      @finish="searchTableList"
      @closeDrawer="addEditDrawerVisible = false" />
    <ykc-operation-dialog
      :hasContentBorder="true"
      :show.sync="showDetailDialog"
      size="large"
      :show-footer="false"
      :customTitle="true">
      <template slot="title">
        <div class="detail-title">
          <div class="detail-title-left">{{ detailTitle }}</div>
          <div class="detail-title-right">推送时间：{{ detailTime }}</div>
        </div>
      </template>
      <template slot="content">
        <div class="dialog-operation-content">
          {{ detailContent }}
        </div>
      </template>
    </ykc-operation-dialog>
    <ykc-operation-dialog
      :show.sync="showDownloadlDialog"
      size="large"
      :show-footer="false"
      :customTitle="true"
      class="merchant-dialog">
      <template slot="title">
        <div class="merchant-title">
          <div class="merchant-title-left">推送商户</div>
          <div class="merchant-title-right" @click="doDownload" v-rbac="'sys:notice:batchdownload'">
            <div class="merchant-title-right-icon"></div>
            <div class="merchant-title-right-text">下载</div>
          </div>
        </div>
      </template>
      <template slot="content">
        <MerchantDialog
          @total="val => (totalMerchant = val)"
          :showDialog="showDownloadlDialog"
          :noticeId="noticeId" />
      </template>
    </ykc-operation-dialog>
  </scroll-layout>
</template>

<script>
  import YkcDialog from '@/components/ykc-ui/dialog';
  import AddOrEdit from './AddOrEdit.vue';
  import MerchantDialog from './MerchantDialog.vue';
  import { sysNotice } from '@/service/apis';
  import { offlineExport, code as hasPermission } from '@/utils';

  export default {
    name: 'noticeManageList',
    components: {
      AddOrEdit,
      MerchantDialog,
    },
    computed: {
      searchData() {
        return [
          {
            comName: 'YkcInput',
            key: 'noticeTitle',
            label: '公告标题',
            placeholder: '请输入公告标题',
          },
          {
            comName: 'YkcDatePicker',
            key: 'publishTime',
            label: '推送时间 ',
            placeholder: '请选择推送时间',
          },
          {
            comName: 'YkcDropdown',
            key: 'noticeType',
            label: '公告类型',
            placeholder: '请选择公告类型',
            data: [
              {
                id: '0',
                name: '营销公告',
              },
              {
                id: '1',
                name: '系统公告',
              },
            ],
          },
        ];
      },
    },
    data() {
      return {
        totalMerchant: 0,
        noticeId: '',
        showDownloadlDialog: false,
        detailTitle: '', // 详情名称
        detailTime: '', // 详情时间
        showDetailDialog: false,
        detailContent: '',
        addEditDrawerVisible: false,
        ruleForm: {},
        showCwd: false,
        selectIds: [],
        tableData: [],

        tableColumns: [
          {
            label: '公告类型',
            prop: 'type',
            minWidth: '100px',
            scopedSlots: {
              default: ({ row }) => {
                const noticeTypeMap = {
                  0: '营销公告',
                  1: '系统公告',
                  null: '——',
                };
                const noticeType = noticeTypeMap[row.noticeType] || '——';
                return (
                  <div>
                    <span title={noticeType}>{noticeType}</span>
                  </div>
                );
              },
            },
          },
          { label: '公告标题', prop: 'noticeTitle', minWidth: '80px' },
          { label: '推送时间', prop: 'publishTime', minWidth: '100px' },
          {
            label: '推送商户',
            prop: 'pushOperaNum',
            minWidth: '140px',
            scopedSlots: {
              default: ({ row }) => {
                return (
                  <span
                    class="link"
                    onClick={() => this.handleMerchantClick(row)}
                    title={row.pushOperaNum}>
                    {row.pushOperaNum}
                  </span>
                );
              },
            },
          },
        ],
        tableTitle: '消息列表',
        tableOperateButtons: [
          {
            enabled: () => hasPermission('sys:notice:detail'),
            id: '1',
            text: '详情',
            handleClick: (btn, { row }) => {
              console.log('详情', row);
              this.openDetail(row);
            },
          },
          {
            enabled: () => hasPermission('sys:notice:del'),
            id: '2',
            text: '删除',
            handleClick: (btn, { row }) => {
              this.deleteDialog(row);
            },
          },
        ],
        pageInfo: {
          total: 0,
          pageIndex: 1,
          pageSize: 10,
        },
        searchParams: {
          noticeTitle: '',
          publishTime: [],
          noticeType: '',
          publishStartTime: '',
          publishEndTime: '',
        },
      };
    },
    mounted() {
      this.searchTableList();
    },
    methods: {
      // 下载
      doDownload() {
        console.log('doDownload');
        offlineExport(
          {
            downloadType: 'noctice_opera',
            jsonNode: {
              noticeId: this.noticeId,
            },
          },
          this.totalMerchant,
          () => {
            this.showDownloadlDialog = false;
          }
        );
      },
      handleMerchantClick(row) {
        console.log('handleMerchantClick', row);
        this.noticeId = row.id;
        this.showDownloadlDialog = true;
      },
      /** 根据条件查询数据 */
      searchTableList() {
        const reqParams = {
          ...this.searchParams,
          current: this.pageInfo.pageIndex,
          size: this.pageInfo.pageSize,
        };
        sysNotice
          .queryNoticeList(reqParams)
          .then(res => {
            console.log(res);
            this.tableData = res?.records || [];
            this.pageInfo.total = res?.total;
          })
          .catch(() => {
            this.tableData = [];
            this.pageInfo.total = 0;
          });
      },
      /** 点击查询按钮 */
      handleSearchConfirm(form) {
        console.log('form', form);
        this.pageInfo.pageIndex = 1;
        Object.assign(this.searchParams, form);
        if (form.publishTime.length > 0) {
          [this.searchParams.publishStartTime, this.searchParams.publishEndTime] = form.publishTime;
        }
        this.searchTableList();
      },
      /** 点击清空查询按钮 */
      handleSearchClean(form) {
        Object.assign(this.searchParams, form);
      },
      /** 分页 change 事件监听器 */
      handlePaginationCurrentChange(current) {
        this.pageInfo.pageIndex = current;
        this.searchTableList();
      },
      /** 分页 size  change  事件监听器 */
      handlePaginationSizeChange(size) {
        this.pageInfo.pageIndex = 1;
        this.pageInfo.pageSize = size;
        this.searchTableList();
      },
      // 勾选
      handleSelect(selection, row) {
        console.log(selection, row);
      },
      /**
       * 获取勾选id
       * */
      selectionChange(selection) {
        this.selectIds = selection.map(item => {
          return item.id;
        });
      },
      // 批量删除
      batchDelete() {
        if (this.selectIds.length === 0) {
          this.$message.warning('请选择条目后进行批量操作！');
          return;
        }
        this.deleteFun(this.selectIds);
      },
      // 批量下载
      batchDownload() {
        if (this.selectIds.length === 0) {
          this.$message.warning('请选择条目后进行批量操作！');
          return;
        }
        offlineExport({
          downloadType: 'noctice',
          jsonNode: {
            noticeIdList: this.selectIds,
          },
        });
      },
      /** 新增 */
      add() {
        this.addEditDrawerVisible = true;
      },
      // 删除
      deleteDialog(row) {
        this.deleteFun([row.id]);
      },
      // 删除方法
      deleteFun(reqParams) {
        const self = this;
        YkcDialog({
          showTitle: false,
          showSuccessIcon: false,
          type: 'success',
          dialogType: 'feedback',
          desc: '删除公告后无法恢复，请谨慎操作！',
          onClose(done) {
            done();
          },
          onCancel(done) {
            done();
          },
          onConfirm(done) {
            sysNotice.delete(reqParams).then(res => {
              console.log(res);
              self.searchTableList();
              done();
            });
          },
        });
      },
      // 详情
      openDetail(row) {
        this.detailTitle = row.noticeTitle;
        this.detailTime = row.publishTime;
        this.detailContent = row.noticeContent;
        this.showDetailDialog = true;
      },
    },
  };
</script>
<style lang="scss" scoped>
  .ykc-table-cell-wrap-enable {
    position: relative;
    padding-left: 9px;
    &::before {
      content: ' ';
      position: absolute;
      top: calc(50% - 2.5px);
      left: 0;
      width: 5px;
      height: 5px;
      background: #02c498;
      border-radius: 50%;
    }
  }
  .ykc-table-cell-wrap-deactivate {
    position: relative;
    padding-left: 9px;
    &::before {
      content: ' ';
      position: absolute;
      top: calc(50% - 2.5px);
      left: 0;
      width: 5px;
      height: 5px;
      background: #d0021b;
      border-radius: 50%;
    }
  }

  .link {
    color: blue;
    cursor: pointer;
  }

  .dialog-operation-content {
    padding: 7px 15px 7px 7px;
  }
  .detail-title {
    display: flex;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    padding-right: 20px;
    &-left {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &-right {
      white-space: nowrap;
      margin-left: 8px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
    }
  }
  .merchant-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    padding-right: 20px;
    &-left {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &-right {
      cursor: pointer;
      display: flex;
      align-items: center;
      position: relative;
      top: 1px;
      &-icon {
        width: 12px;
        height: 12px;
        background: no-repeat url('~@/assets/images/systemSettings/download.png');
        background-size: 100% 100%;
      }
      &-text {
        white-space: nowrap;
        margin-left: 4px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
      }
    }
  }
  .merchant-dialog {
    ::v-deep .el-scrollbar__wrap {
      max-height: 450px;
    }
  }
</style>
