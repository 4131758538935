var render = function render(){var _vm=this,_c=_vm._self._c;return _c('scroll-layout',[_c('template',{slot:"header"},[_c('ykc-search',{ref:"YkcSearch",attrs:{"data":_vm.searchData,"searchForm":_vm.searchParams},on:{"click":_vm.handleSearchConfirm,"clean":_vm.handleSearchClean}})],1),_c('div',{staticClass:"motorcade-main-account"},[_c('ykc-table',{ref:"YkcTable",attrs:{"operateFixedType":"right","data":_vm.tableData,"title":_vm.tableTitle,"columns":_vm.tableColumns,"operateButtons":_vm.tableOperateButtons.filter(item => item.enabled()),"selectable":true,"selectOnIndeterminate":false,"rowSelectable":row => {
          if (['0', '2'].includes(row.downloadStatus)) {
            return false;
          } else {
            return true;
          }
        }},on:{"select":_vm.handleSelect,"selection-change":_vm.selectionChange},scopedSlots:_vm._u([{key:"headerButtons",fn:function(){return [_c('div',{staticClass:"table-header-button"},[_c('ykc-button',{directives:[{name:"rbac",rawName:"v-rbac",value:('sys:download:batchdownload'),expression:"'sys:download:batchdownload'"}],attrs:{"type":"plain"},on:{"click":_vm.batchDownload}},[_vm._v(" 批量下载 ")])],1)]},proxy:true}])},[_c('ykc-pagination',{ref:"YkcPagination",attrs:{"slot":"pagination","total":_vm.pageInfo.total,"current-page":_vm.pageInfo.pageIndex,"page-size":_vm.pageInfo.pageSize},on:{"update:currentPage":function($event){return _vm.$set(_vm.pageInfo, "pageIndex", $event)},"update:current-page":function($event){return _vm.$set(_vm.pageInfo, "pageIndex", $event)},"update:pageSize":function($event){return _vm.$set(_vm.pageInfo, "pageSize", $event)},"update:page-size":function($event){return _vm.$set(_vm.pageInfo, "pageSize", $event)},"current-change":_vm.handlePaginationCurrentChange,"size-change":_vm.handlePaginationSizeChange},slot:"pagination"})],1)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }