<template>
  <ykc-table
    ref="YkcTable"
    operateFixedType="right"
    :data="tableData"
    :title="tableTitle"
    :columns="tableColumns"
    :operateButtons="tableOperateButtons">
    <ykc-pagination
      slot="pagination"
      ref="YkcPagination"
      :total="pageInfo.total"
      :current-page.sync="pageInfo.pageIndex"
      :page-size.sync="pageInfo.pageSize"
      @current-change="handlePaginationCurrentChange"
      @size-change="handlePaginationSizeChange"></ykc-pagination>
  </ykc-table>
</template>
<script>
  import { sysNotice } from '@/service/apis';

  export default {
    props: {
      showDialog: {
        type: Boolean,
        default: false,
      },
      noticeId: {
        type: Number,
      },
    },
    data() {
      return {
        tableData: [],
        tableColumns: [
          { label: '运营商名称', prop: 'stationOperatorName', minWidth: '80px' },
          { label: '运营商ID', prop: 'stationOperator', minWidth: '100px' },
          {
            label: '推送状态',
            prop: 'pushStatus',
            minWidth: '140px',
            scopedSlots: {
              default: ({ row }) => {
                const pushStatusMap = {
                  0: '未推送',
                  1: '已推送',
                  2: '已读',
                };

                return (
                  <span title={pushStatusMap[row.pushStatus] || '——'}>
                    {pushStatusMap[row.pushStatus] || '——'}
                  </span>
                );
              },
            },
          },
        ],
        tableTitle: '',
        tableOperateButtons: [],
        pageInfo: {
          total: 0,
          pageIndex: 1,
          pageSize: 10,
        },
      };
    },
    watch: {
      showDialog: {
        handler(val) {
          if (val) {
            this.searchTableList();
          } else {
            this.pageInfo.pageIndex = 1;
          }
        },
        deep: true,
        immediate: true,
      },
    },
    methods: {
      searchTableList() {
        const reqParams = {
          noticeId: this.noticeId,
          current: this.pageInfo.pageIndex,
          size: this.pageInfo.pageSize,
        };
        sysNotice.pushOperatorList(reqParams).then(res => {
          this.tableData = res?.records || [];
          this.pageInfo.total = res.total || 0;
          this.$emit('total', this.pageInfo.total);
        });
      },
      /** 分页 change 事件监听器 */
      handlePaginationCurrentChange(current) {
        this.pageInfo.pageIndex = current;
        this.searchTableList();
      },
      /** 分页 size  change  事件监听器 */
      handlePaginationSizeChange(size) {
        this.pageInfo.pageIndex = 1;
        this.pageInfo.pageSize = size;
        this.searchTableList();
      },
    },
  };
</script>
<style lang="scss" scoped>
  .ykc-table {
    box-shadow: none;
    display: flex;
    flex-direction: column;
    // overflow-y: auto;
    ::v-deep .el-table {
      border-top: none;
      padding-bottom: 30px;
    }
    ::v-deep .ykc-table-pagination-wrapper {
      position: absolute;
      bottom: 0;
      left: 0;
      background: #ffffff;
      width: 100%;
      padding-bottom: 20px;
      .ykc-pagination {
        justify-content: center !important;
      }
    }
  }
</style>
